<template>
  <div>
    <label>{{ data.label }} <required :data="data"/></label>
    <div class="info" v-if="data.description">
      <icon id="circle-info" /> &nbsp;
      <span v-html="data.description.split('\n').join('<br />')"></span>
    </div>
    <ul>
      <li v-for="value in values" :key="value.value">
        <a href="#" @click.prevent="setClick(value.value)"
          ><icon
            class="icon"
            id="square-empty"
            v-if="clicked !== value.value"
          />
          <icon class="icon" v-if="clicked === value.value" id="square-check" />
          {{ value.label }}</a
        >
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  components: {
    required() {
      return import("./required.vue");
    }
  },
  props: {
    data: Object
  },
  data() {
    return {
      clicked: false,
      values: [
        { value: 1, label: "Ja" },
        { value: 0, label: "Nein" }
      ]
    };
  },
  computed: {},
  mounted() {},
  methods: {
    setClick(value) {
      this.clicked = value;
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
.info
  margin-top: 3px
  color: $info
  font-size: 9pt
label
  font-weight: bold
ul
  margin: 0
  padding: 0
  list-style: none
  display: flex
  flex-direction: column
  margin-top: 10px
  li
    a
      text-decoration: none
      color: $dark-grey
      .icon
        color: $success
</style>
